<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Villa Booking</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Villa Booking</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6"></div>
                    <div class="float-end col-6">
                      <router-link
                        :to="{ name: 'booking' }"
                        class="btn btn-gradient-primary float-end"
                      >
                        Back
                      </router-link>
                    </div>
                  </div>
                </div>
                <section>
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <form
                          class="add-new-user pt-0"
                          method="post"
                          @submit.prevent="submitForm()"
                        >
                          <input type="hidden" name="id" />
                          <div class="flex-grow-1">
                            <div class="p-1 sectionBlock" id="section-block">
                              <div class="row">
                                <div class="col-6">
                                  <div class="mb-1">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >Check in - out date</label
                                    >
                                    <Datepicker
                                      type="date"
                                      @closed="dateChange()"
                                      :min="
                                        new Date().toISOString().substr(0, 10)
                                      "
                                      v-model="form.rangeDate"
                                      format="dd-MM-yyyy"
                                      value-format="dd-MM-yyyy"
                                      :min-date="new Date()"
                                      modelAuto
                                      range
                                      multiCalendars
                                      multiCalendarsSolo
                                    />
                                  </div>
                                  <div
                                    class="mb-1 border p-1 villaCls"
                                    style="background-color: #fcfcfc"
                                  >
                                    <h4>Villa Type & No of Villa</h4>

                                    <div
                                      class="px-1"
                                      v-for="(item, index) in villa"
                                      :key="item.villaTypeId"
                                    >
                                      <div class="row" style="min-height: 10px">
                                        <div
                                          class="fw-bold fst-italic text-primary"
                                        >
                                          Adult Limit {{ item.allow_adult }},
                                          Extra charge per person
                                          <b>{{ item.extra_per_person_charge }}</b> upto
                                          {{ item.max_adult_limit }} Adult
                                        </div>
                                      </div>
                                      <div
                                        class="row m-1 border-bottom"
                                        style="min-height: 38px"
                                      >
                                        <div
                                          class="col-4 align-items-center d-flex form-check form-check-inline"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            :id="`inlineCheckbox` + index"
                                            name="villa_type_id"
                                            @change="
                                              selectVillaType($event, item)
                                            "
                                          />&nbsp;&nbsp;
                                          <label
                                            class="form-check-label"
                                            :for="`inlineCheckbox` + index"
                                            >{{ item.villa_name }}</label
                                          >
                                        </div>
                                        <div class="col-4 wrapper-option">
                                          <div
                                            class="option"
                                            v-show="
                                              villa_selected.filter((el) => {
                                                return (
                                                  el.villaTypeId ==
                                                  item.villaTypeId
                                                );
                                              })[0]
                                            "
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                                fill="#153936"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                                fill="#153936"
                                              />
                                            </svg>
                                            <select
                                              @change="addVilla($event, item)"
                                            >
                                              <option
                                                v-for="(
                                                  item1, index1
                                                ) in item.villa_quantity"
                                                :value="index1 + 1"
                                                :key="item1"
                                              >
                                                {{ index1 + 1 }}
                                              </option>
                                            </select>
                                          </div>
                                          <div
                                            class="option"
                                            v-show="
                                              villa_selected.filter((el) => {
                                                return (
                                                  el.villaTypeId ==
                                                  item.villaTypeId
                                                );
                                              })[0]
                                            "
                                          >
                                            <svg
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                                fill="#153936"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                                fill="#153936"
                                              />
                                            </svg>
                                            <select
                                              @change="addPeople($event, item)"
                                            >
                                              <option
                                                v-for="(
                                                  item2, index2
                                                ) in item.allow_adult"
                                                :value="index2 + 1"
                                                :key="item2"
                                              >
                                                {{ index2 + 1 }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-1">
                                    <div class="row">
                                      <div class="col-6">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Villa Amount</label
                                        >
                                        <input
                                          type="number"
                                          class="form-control"
                                          name="amount"
                                          v-model="form.amount"
                                          id="amount"
                                          step="any"
                                          placeholder="Amount"
                                          readonly
                                        />
                                      </div>
                                      <div class="col-6">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Extra Charges</label
                                        >
                                        <input
                                          type="number"
                                          class="form-control"
                                          name="extra_charge"
                                          v-model="form.extra_charge"
                                          id="extra_charge"
                                          step="any"
                                          placeholder="Extra Charges"
                                          readonly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mb-1">
                                    <div class="row">
                                      <div class="col-6">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Discount Amount</label
                                        >
                                        <input
                                          readonly
                                          type="number"
                                          class="form-control"
                                          name="coupon_amount"
                                          v-model="form.coupon_amount"
                                          id="coupon_amount"
                                          step="any"
                                          placeholder="Discount Amount"
                                        />
                                      </div>
                                      <div class="col-6">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >GST Amount</label
                                        >
                                        <input
                                          type="number"
                                          class="form-control"
                                          name="gst_amount"
                                          v-model="form.gst_amount"
                                          id="gst_amount"
                                          step="any"
                                          placeholder="GST Amount"
                                          readonly
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mb-1">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >Final Amount</label
                                    >
                                    <input
                                      type="number"
                                      class="form-control"
                                      name="final_amount"
                                      v-model="form.final_amount"
                                      id="final_amount"
                                      step="any"
                                      placeholder="Final Amount"
                                      readonly
                                    />
                                  </div>
                                  <div class="mb-1">
                                    <div class="row">
                                      <div class="col-9">
                                        <label
                                          class="form-label"
                                          for="basic-addon-name"
                                          >Coupon Code</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="coupon_code"
                                          v-model="form.coupon_code"
                                          placeholder="Coupon Code"
                                        />
                                      </div>
                                      <div class="col-3">
                                        <button
                                          type="button"
                                          class="btn btn-primary applybtn"
                                          @click="applyCoupanCode()"
                                          :disabled="
                                            form.check_in_date == '' &&
                                            form.check_out_date == ''
                                          "
                                        >
                                          Apply
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="mb-1">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >Payment Mode</label
                                    >
                                    <Select2
                                      v-model="form.payment_mode_id"
                                      :options="$store.state.paymentModeData"
                                      :settings="{
                                        placeholder: 'Choose Payment Mode',
                                      }"
                                    />
                                    <div
                                      class="invalid-feedback fw-bold fst-italic"
                                      :style="{ display: 'none' }"
                                    >
                                      Please Choose Payment Mode.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="p-1 mt-2 sectionBlock"
                              v-show="villa_selected.length"
                            >
                              <div class="row">
                                <div class="col-12">
                                  <div
                                    class="invoice-padding invoice-product-details"
                                  >
                                    <div class="source-item">
                                      <div data-repeater-list="group-a">
                                        <div
                                          v-for="(item, index) in form.persons"
                                          :key="index"
                                        >
                                          <div
                                            :class="
                                              index === 0
                                                ? `border-bottom sectionBlock mb-1 pt-1 pb-1`
                                                : index === 1
                                                ? `selectioBoxOther pt-1`
                                                : ``
                                            "
                                            class="d-flex row"
                                          >
                                            <h4 v-show="index === 0">
                                              Primary Member Detail
                                            </h4>
                                            <h4 v-show="index === 1">
                                              Other Member Detail
                                            </h4>
                                            <div class="col-md-2 col-sm-6">
                                              <div class="mb-1">
                                                <label
                                                  class="form-label"
                                                  for="basic-addon-name"
                                                  >First Name</label
                                                >
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  name="first_name"
                                                  placeholder="First Name"
                                                  v-model="
                                                    form.persons[index]
                                                      .first_name
                                                  "
                                                />
                                                <!-- <div class="fw-bold fst-italic error" > Please enter First name. </div> -->
                                              </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                              <div class="mb-1">
                                                <label
                                                  class="form-label"
                                                  for="basic-addon-name"
                                                  >Middle Name</label
                                                >
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  name="middle_name"
                                                  placeholder="Middle Name"
                                                  v-model="
                                                    form.persons[index]
                                                      .middle_name
                                                  "
                                                />
                                                <!-- <div class="fw-bold fst-italic error" > Please enter Middle name. </div> -->
                                              </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                              <div class="mb-1">
                                                <label
                                                  class="form-label"
                                                  for="basic-addon-name"
                                                  >Last Name</label
                                                >
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  name="last_name"
                                                  placeholder="Last Name"
                                                  v-model="
                                                    form.persons[index]
                                                      .last_name
                                                  "
                                                />
                                                <!-- <div class="fw-bold fst-italic error" > Please enter Last name. </div> -->
                                              </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                              <div class="mb-1">
                                                <label
                                                  class="form-label"
                                                  for="basic-addon-name"
                                                  >Mobile</label
                                                >
                                                <input
                                                  type="number"
                                                  class="form-control"
                                                  name="mobile"
                                                  v-model="
                                                    form.persons[index].mobile
                                                  "
                                                  id="mobile"
                                                  placeholder="Mobile"
                                                />
                                                <!-- <div class="error fw-bold fst-italic" v-show="mobileError[index]" > Please enter Mobile. </div> -->
                                              </div>
                                            </div>
                                            <div class="col-md-2 col-sm-6">
                                              <div class="mb-1">
                                                <label
                                                  class="form-label"
                                                  for="basic-addon-name"
                                                  >Id Proof</label
                                                >
                                                <input
                                                  type="file"
                                                  class="form-control"
                                                  name="id_proof"
                                                  ref="proofFile"
                                                  placeholder="Id Proof"
                                                  :id="index"
                                                  @change="
                                                    uploadProofImage(
                                                      index,
                                                      $event
                                                    )
                                                  "
                                                />
                                              </div>
                                            </div>
                                            <div
                                              class="col-md-1 col-sm-6 img-view"
                                              :class="
                                                form.persons[index].id_proof
                                                  ? ``
                                                  : `border`
                                              "
                                            >
                                              <div id="user-avatar-section">
                                                <div
                                                  id="d-flex justify-content-start"
                                                >
                                                  <img
                                                    v-if="
                                                      form.persons[index]
                                                        .id_proof
                                                    "
                                                    :src="
                                                      form.persons[index]
                                                        .id_proof
                                                    "
                                                    class="img-fluid rounded"
                                                    height="70"
                                                    width="70"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              class="col-md-1 col-sm-6 trash-view"
                                            >
                                              <div
                                                class="d-flex"
                                                v-if="index !== 0"
                                              >
                                                <vue-feather
                                                  type="trash-2"
                                                  size="20"
                                                  @click="
                                                    removeContent(
                                                      index,
                                                      form.persons[index]
                                                        .detail_id
                                                    )
                                                  "
                                                ></vue-feather
                                                >&nbsp;&nbsp;
                                              </div>
                                              <span
                                                class="badge badge-light-primary rounded-pill float-end"
                                                >{{ index + 1 }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="row mt-1"
                                        v-show="
                                          form.persons.length <
                                          villa_pricing.person
                                        "
                                      >
                                        <div class="col-12">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn-sm btn-add-new float-end"
                                            @click="addToContent()"
                                            data-repeater-create
                                          >
                                            <vue-feather
                                              type="plus"
                                              size="15"
                                            ></vue-feather>
                                            <span class="align-middle"
                                              >Add Person</span
                                            >
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-12 text-center">
                                <button
                                  type="submit"
                                  class="btn btn-primary me-1 data-submit"
                                  v-show="villa_selected.length > 0 && !payurl"
                                  :disabled="disabled"
                                >
                                  Book Now
                                </button>
                                <button
                                  type="reset"
                                  :disabled="disabled"
                                  @click="resetForm()"
                                  class="btn btn-outline-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import Select2 from "vue3-select2-component";

export default {
  name: "BookingAdd",
  components: {
    Datepicker,
    Select2,
  },
  data() {
    return {
      form: new Form({
        id: null,
        rangeDate: "",
        check_in_date: "",
        check_out_date: "",
        amount: "",
        coupon_amount: "",
        coupon_code: "",
        gst_amount: "",
        extra_charge: "",
        final_amount: "",
        payment_mode_id: "",
        total_adult: "",
        total_child: "",
        total_villa: [],
        is_user_exist: false,
        persons: [
          {
            detail_id: "",
            user_id: "",
            barcode_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile: "",
            id_proof: "",
            is_primary: true,
            user_type: "Guest",
          },
        ],
      }),
      villa_pricing: {
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        person: 0,
        total: 0,
      },
      villa: [],
      villa_selected: [],
      totalAdultError: "none",
      errors: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      disabled: false,
      payurl: null,
    };
  },
  beforeCreate() {
    var element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
    }
  },
  async mounted() {
    this.$store.dispatch("getPaymentModeList");
  },
  methods: {
    resetForm() {
      this.form.rangeDate = "";
      this.form.check_in_date = "";
      this.form.check_out_date = "";
      this.villa = [];
      this.villa_selected = [];
      this.form.persons = [];
      this.form.persons.push({
        detail_id: "",
        user_id: "",
        barcode_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile: "",
        id_proof: "",
        is_primary: true,
        user_type: "Guest",
      });
    },
    dateChange() {
      this.dateResult();
      this.checkvalidity();
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          this.form.check_in_date = moment(date[0]).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date[1]).format("YYYY-MM-DD");
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
        }
      }
    },
    async checkvalidity() {
      if (this.form.coupon_code) {
        this.form.amount = "";
        this.form.coupon_amount = "";
        this.form.gst_amount = "";
        this.form.final_amount = "";
      }
      this.dateResult();
      if (this.form.rangeDate) {
        await axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/search-villa",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              const villa_ins = response.data.data;
              villa_ins.forEach(function (value, i) {
                villa_ins[i].villa_type_id = value.villaTypeId;
              });
              this.villa = villa_ins;
            } else if (response.data.code == 400) {
              this.$refs.total_adult.focus();
              this.$toast.warning(response.data.error?.[0]);
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      }
    },
    addVilla(event, item) {
      this.villa_selected[
        this.villa_selected.findIndex(
          (obj) => obj.villaTypeId == item.villaTypeId
        )
      ].modified_item.quantity = parseInt(event.target.value);
      this.getVillaPrice();
    },
    addPeople(event, item) {
      this.villa_selected[
        this.villa_selected.findIndex(
          (obj) => obj.villaTypeId == item.villaTypeId
        )
      ].person = parseInt(event.target.value);
      this.villa_selected[
        this.villa_selected.findIndex(
          (obj) => obj.villaTypeId == item.villaTypeId
        )
      ].modified_item.person = parseInt(event.target.value);
      this.getVillaPrice();
    },
    selectVillaType(event, item) {
      var modified_item = {
        villa_type_id: item.villaTypeId,
        check_in_date: this.form.check_in_date,
        check_out_date: this.form.check_out_date,
        person: 1,
        quantity: 1,
        villa_name: item.villa_name,
      };

      item.modified_item = modified_item;
      item.person = 1;

      if (this.villa_selected.includes(item)) {
        var index = this.villa_selected.indexOf(item);
        if (index > -1) {
          this.villa_selected.splice(index, 1);
        }
        this.getVillaPrice();
      } else {
        this.villa_selected.push(item);
        this.getVillaPrice();
      }
    },
    async getVillaPrice() {
      this.villa_pricing.total = parseInt(0);
      this.villa_pricing.extra_person_charge = parseInt(0);
      this.villa_pricing.festival_charge = parseInt(0);
      this.villa_pricing.gst_tax = parseInt(0);
      this.villa_pricing.person = parseInt(0);

      if (this.form.coupon_amount <= 0 && this.form.coupon_amount == "") {
        this.form.amount = "";
        this.form.coupon_amount = "";
        this.form.gst_amount = "";
        this.form.final_amount = "";
      }
      this.dateResult();

      var booking_arr = [];

      if (this.villa_selected.length) {
        this.villa_selected.forEach((item) => {
          booking_arr.push(item.modified_item);
        });
      }

      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: booking_arr,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            const res = response.data.data;

            this.villa_pricing.total = parseInt(res.total);
            this.villa_pricing.extra_person_charge = parseInt(
              res.extra_person_charge
            );
            this.villa_pricing.festival_charge = parseInt(res.festival_charge);
            this.villa_pricing.gst_tax = parseInt(res.gst_tax);
            this.villa_pricing.person = parseInt(res.person);
            this.form.total_adult = parseInt(res.person);

            this.getVillaCalc();
          } else if (response.data.code == 400) {
            this.$refs.total_adult.focus();
            this.$toast.warning(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });

      this.getVillaCalc();
    },
    getVillaCalc(coupon_amount = 0) {
      this.form.amount = this.villa_pricing.total;
      if (coupon_amount > 0) {
        this.form.coupon_amount = coupon_amount;
      }
      const extra_charge =
        this.villa_pricing.extra_person_charge +
        this.villa_pricing.festival_charge;
      this.form.extra_charge = extra_charge;

      const gst_amount =
        ((this.villa_pricing.total +
          this.villa_pricing.extra_person_charge +
          this.villa_pricing.festival_charge -
          this.form.coupon_amount) *
          this.villa_pricing.gst_tax) /
        100;
      this.form.gst_amount = gst_amount;

      const total =
        this.villa_pricing.total + extra_charge - this.form.coupon_amount;

      this.form.final_amount = total + gst_amount;
    },
    addToContent() {
      this.form.persons.push({
        detail_id: this.form.detail_id,
        user_id: this.form.user_id,
        barcode_id: this.form.barcode_id,
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        mobile: this.form.mobile,
        id_proof: this.form.id_proof,
        is_primary: false,
        user_type: "Guest",
      });
      this.form.persons.detail_id = null;
      this.form.persons.user_id = null;
      this.form.persons.barcode_id = null;
      this.form.persons.first_name = "";
      this.form.persons.middle_name = "";
      this.form.persons.last_name = "";
      this.form.persons.mobile = "";
      this.form.persons.id_proof = "";
      this.form.persons.is_primary = "";
      this.form.persons.user_type = "Guest";
    },
    removeContent(idx, detailId = null) {
      if (detailId) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to Delete this User!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            axios
              .post(
                process.env.VUE_APP_API_URL + "/booking/villa/delete-user",
                {
                  detail_id: detailId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${this.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status) {
                  this.form.persons.splice(idx, 1);
                  this.disabled = false;
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      } else {
        this.form.persons.splice(idx, 1);
        this.disabled = false;
      }
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.form.persons[index].id_proof = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    async applyCoupanCode() {
      this.errors = {};

      await axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/coupon-code",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            coupon_code: this.form.coupon_code,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            var res = response.data.data;
            this.getVillaCalc(parseInt(res.coupon_amount));
            this.$toast.success("Coupon Applied!");
          } else if (response.data.code == 400) {
            this.$toast.warning(response.data.error?.[0]);
          } else if (
            response.data.code == 422 &&
            response.data.status == false
          ) {
            this.$toast.warning(response.data.message);
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async submitForm() {
      
      this.dateResult();

      this.firstError = [];
      this.lastError = [];
      this.mobileError = [];
      for (var i = 0; i < this.form.persons.length; i++) {
        if (
          this.form.persons[i].first_name == undefined ||
          this.form.persons[i].first_name == null ||
          this.form.persons[i].first_name == ""
        ) {
          this.firstError[i] = true;
          return;
        }
        if (
          this.form.persons[i].last_name == undefined ||
          this.form.persons[i].last_name == null ||
          this.form.persons[i].last_name == ""
        ) {
          this.lastError[i] = true;
          return;
        }
        if (
          this.form.persons[i].mobile == undefined ||
          this.form.persons[i].mobile == null ||
          this.form.persons[i].mobile == ""
        ) {
          this.mobileError[i] = true;
          return;
        }
      }

      this.disabled = true;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/villa-book";

      this.form.total = this.form.amount;
      this.form.sub_total =
        this.form.amount -
        (this.form.coupon_amount ? this.form.coupon_amount : 0);
      this.form.gst_tax = this.villa_pricing.gst_tax;
      this.form.final_total = this.form.final_amount;
      this.form.villa_booking = this.villa_selected;
      this.form.person = this.form.total_adult;

      await this.form
        .post(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.payurl = null;

            if (this.form.payment_mode_id == 1) {
              this.$toast.success(response.data?.message);
              this.$router.push({name: 'booking'});
            } else {
              this.getPayLink(
                response.data.data.booking_id,
                response.data.data.user_id
              );
            }
          } else {
            this.$toast.error(response.data?.message);
          }

          setTimeout(() => {
            this.disabled = false;
          }, 500);
        });
    },
    async getPayLink(booking_id, user_id) {
      this.payurl = null;

      const formdata = new FormData();
      formdata.append("amount", this.form.final_total);
      formdata.append("user_id", user_id);
      formdata.append("device", "ad");
      formdata.append("mobileNumber", this.form.persons[0].mobile);
      formdata.append("redirectMode", "REDIRECT");
      formdata.append("booking_id", booking_id);
      formdata.append("redirectUrl", process.env.VUE_APP_URL + "/booking");

      await axios
        .post(process.env.VUE_APP_API_CUS_URL + "/pay-online", formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        // await axios.post("https://crm-server.riocolina.com/api/customer/pay-online", formdata)
        .then((response) => {
          if (response.data.links) {
            this.payurl = response.data.links.pay_link_web;
            window.location.href = response.data.links.pay_link_web;
          }
        });
    },
  },
};
</script>
<style>
.card-datatable {
  overflow-x: auto;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}

.btn-add-new span {
  margin-top: 5px;
}

.btn-add-new {
  display: flex;
}

.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}

.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}

.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}

.trash-view i:hover {
  transform: translateY(-2px);
}

.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}

.error {
  color: #ea5455;
  font-size: 0.857rem;
}

.form-check {
  padding-left: 0.785rem !important;
}

.status-badge {
  position: relative;
  cursor: pointer;
}

.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}

.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}

.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}

.statusZindex {
  z-index: -1;
}

.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}

.mobileSearchDiv {
  position: relative;
}

.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}

.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.search-mobile:focus {
  border-color: #7367f0 !important;
}

.wrapper-option {
  display: flex;
}

.applybtn {
  margin-top: 23px;
  width: 100%;
}
</style>
